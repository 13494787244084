$(function(){
	new WOW().init();
    $.ajaxSetup({
        beforeSend: function(xhr,data) {
          xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));
        }
    });


	if (!window.sessionStorage.loaded) {
		$('body').addClass('initial');
		$('#loader').css({'background-color':'#cecece'});
		$('#loader svg').removeClass('hide');
		window.sessionStorage.setItem("loaded",true);
	} else {
		$('#loader').css({'background-color':'transparent'});
	}

  Pace.on('done',function(){
    setTimeout(function(){
		$('#loader').fadeOut();
		$('.pace-progress').addClass('hide');
      	$('body').css({'overflow':'auto'});
    },1000);
  });



	var applicantForm = $('.applicant-form');
	var contactForm = $('.contact-form');
	var emailForm = $('.email-form');

	if (applicantForm.length > 0) {
		applicantForm.submit(function(e) {
			e.preventDefault();
		});

		applicantForm.parsley().on('form:submit', function() {
			// if ( !validateForm() ) {
			// 	return false;
			// } else {
				var url = applicantForm.attr('action');
				// var data = applicantForm.serialize();
			
				var data = new FormData();
				var formParams = applicantForm.serializeArray();

				$.each(applicantForm.find('input[type="file"]'), function(i, tag) {
					$.each($(tag)[0].files, function(i, file) {
						data.append(tag.name, file);
					});
				});

				$.each(formParams, function(i, val) {
					data.append(val.name, val.value);
				});

				var method = $('input[name="_method"]').val();
				var type = (_.isUndefined(method) ? 'POST' : method);
				submitForm(url, data, type);
				return false;
			// }
		});
	}

	if (contactForm.length > 0) {
		contactForm.submit(function (e) {
			e.preventDefault();
		});

		contactForm.parsley().on('form:submit', function () {
			// if ( !validateForm() ) {
			// 	return false;
			// } else {
			var url = contactForm.attr('action');
			var data = contactForm.serialize();
			var method = $('input[name="_method"]').val();
			var type = (_.isUndefined(method) ? 'POST' : method);
			submitContactForm(url, data, type);
			return false;
			// }
		});
	}

	if (emailForm.length > 0) {
		emailForm.submit(function (e) {
			e.preventDefault();
		});

		emailForm.parsley().on('form:submit', function () {
			var url = emailForm.attr('action');
			var data = emailForm.serialize();
			var method = $('input[name="_method"]').val();
			var type = (_.isUndefined(method) ? 'POST' : method);
			submitEmailForm(url, data, type);
			return false;
		});
	}

	function submitForm(url, data, type) {
		applicantForm.find('button').prop('disabled',true).addClass('temp-disabled');
		$.ajax({
			type : type,
			url: url,
			data : data,
			dataType : 'json',
			processData: false,
			contentType: false,
			success : function(data) {
				var title = data.notifTitle;
				var message = data.notifTitle;
				// var message = (_.isUndefined(data.notifMessage) ? '' : data.notifMessage);
				showNotify(title, message);
				resetForm(applicantForm);
			},
			error : function(data, text, error) {
				var message = '';
				_.each(data.responseJSON, function(val) {
					message += val + ' ';
				});
				showNotify('Error saving.', message);
			}
		});
	}

	function submitContactForm(url, data, type) {
		contactForm.find('button').prop('disabled', true).addClass('temp-disabled');
		$.ajax({
			type: type,
			url: url,
			data: data,
			dataType: 'json',
			processData: false,
			success: function (data) {
				var title = data.notifTitle;
				var message = data.notifTitle;
				// var message = (_.isUndefined(data.notifMessage) ? '' : data.notifMessage);
				showNotify(title, message);
				resetForm(contactForm);
			},
			error: function (data, text, error) {
				var message = '';
				_.each(data.responseJSON, function (val) {
					message += val + ' ';
				});
				showNotify('Error saving.', message);
			}
		});
	}

	function submitEmailForm(url, data, type) {
		emailForm.find('button').prop('disabled', true).addClass('temp-disabled');
		$.ajax({
			type: type,
			url: url,
			data: data,
			dataType: 'json',
			processData: false,
			success: function (data) {
				var title = data.notifTitle;
				var message = data.notifTitle;
				// var message = (_.isUndefined(data.notifMessage) ? '' : data.notifMessage);
				showNotify(title, message);
			},
			error: function (data, text, error) {
				var message = '';
				_.each(data.responseJSON, function (val) {
					message += val + ' ';
				});
				showNotify('Error saving.', message);
			}
		});
		resetForm(emailForm);
	}

	function resetForm(form) {
		form.find('input[name!="_token"]').val('');
		form.find('textarea').val('');
		form.find('.temp-disabled').prop('disabled',false).removeClass('temp-disabled');
		form.find('.file-input-name').remove();
		$('.error-container').text('');
	}

	function showNotify(title, message, timer) {
		// message = (_.isUndefined(message) ? '' : message);
		// timer = (_.isUndefined(timer) ? 5000 : timer);
		// swal({
		//   	title: title,
		//   	text: message,
		// 		timer: timer,
		//   	confirmButtonColor: '#ffc805',
		// 		showCloseButton: true,
		// 		showConfirmButton: true
		// });
		$('.modal-body').html(message);
		$('#modalTH').modal('show');
	}

	$('.file-input').on('input', function () {
		var filePath = $(this).val();
		var fileName = filePath.substr(filePath.lastIndexOf('\\') + 1);
		var container = $(this).closest('.upload-btn-wrapper');
		container.find('.file-input-name').remove();
		if (filePath) {
		var htmlAppend = '<div class="file-input-name">'+fileName+'<i class="fa fa-check"></i></div>';
			container.append(htmlAppend);
		}
	})
});